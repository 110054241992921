import { ReactNode, useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthProvider";

type ProtectedRouteProps = {
  children: ReactNode;
  allowedUIDs: string[];
};

export default function ProtectedRoute({
  children,
  allowedUIDs,
}: ProtectedRouteProps) {
  const { user } = useContext(AuthContext) || {
    user: null,
    loading: true,
  };
  const location = useLocation();

  if (!user || !allowedUIDs.includes(user.uid)) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return <>{children}</>;
}
