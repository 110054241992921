import { signInWithEmailAndPassword } from "firebase/auth";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { allowedUIDs } from "../../firebase/allowedUIDs";
import { auth } from "../../firebase/firebaseConfig";
import { Credentials } from "../../types";

export default function Form() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Credentials>();

  const [authError, setAuthError] = useState<string | null>(null);

  const navigate = useNavigate();

  const onSubmit = async (data: Credentials) => {
    const { email, password } = data;

    try {
      const userCredentials = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const { uid } = userCredentials.user;

      if (!allowedUIDs.includes(uid)) {
        setAuthError("Wrong credentials! How did you find this page anyway?");
        return;
      }

      setAuthError(null);
      navigate("/admin/dashboard");
    } catch (error) {
      console.error("Error signing in user", error);
      setAuthError("Wrong credentials! How did you find this page anyway?");
    }
  };

  return (
    <div className="flex flex-col mt-5 w-full text-center">
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          {...register("email", { required: "Email is required." })}
          placeholder="Email"
          type="email"
          className="w-full bg-transparent placeholder:text-themed-orange text-themed-orange py-2 border-b-[1px] border-themed-orange focus:outline-none invalid:border-red-500 invalid:text-red-500"
        />
        {errors.email?.message && (
          <span className="text-red-500">{String(errors.email.message)}</span>
        )}
        <input
          {...register("password", { required: "Password is required." })}
          placeholder="Password"
          type="password"
          className="w-full bg-transparent placeholder:text-themed-orange text-themed-orange py-2 border-b-[1px] border-themed-orange focus:outline-none invalid:border-red-500 invalid:text-red-500 mt-5"
        />
        {errors.password?.message && (
          <span className="text-red-500">
            {String(errors.password.message)}
          </span>
        )}
        <input
          type="submit"
          className="w-full bg-themed-orange py-2 lg:cursor-pointer mt-5"
          value="Log in"
        />
        {authError && <span className="text-red-500">{authError}</span>}
      </form>
    </div>
  );
}
