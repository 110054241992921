import { signOut } from "firebase/auth";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase/firebaseConfig";

export default function Dashboard() {
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      navigate("/admin");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };
  return (
    <>
      <Helmet>
        <title>Resturo Admin Dashboard</title>
      </Helmet>
      <button onClick={handleSignOut} className="bg-white">
        Logout
      </button>
    </>
  );
}
