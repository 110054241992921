import "./global.css";

import { Outlet } from "react-router-dom";
import ScrollProvider from "./contexts/ScrollProvider";

export default function App() {
  return (
    <ScrollProvider>
      <Outlet />
    </ScrollProvider>
  );
}
