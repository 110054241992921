import AnimatedCounter from "./AnimatedCounter";

export default function AboutUs() {
  return (
    <section
      className="relative z-10 flex flex-col items-center justify-around bg-dark w-screen h-[1400px] md:h-[600px] lg:h-[900px] lg:py-36"
      id="about-us"
    >
      <div className="text-default-white w-[300px] text-l md:w-4/5">
        <h2 className="text-2xl mb-2 text-themed-orange">About us</h2>
        <p className="mb-4">
          Resturo is a new loyalty rewards app that improves customer
          experiences at restaurants, coffee shops, and bars.
        </p>
        <p className="mb-4">
          It offers a personalized rewards system through virtual wallets, where
          customers can earn and redeem points for special benefits.
        </p>
        <p>
          Resturo makes managing loyalty programs easy for businesses, helping
          them attract and keep customers with a smooth and engaging rewards
          experience.
        </p>
      </div>
      <div className="flex flex-col justify-between h-[850px] md:flex md:flex-row md:h-auto md:w-4/5 lg:justify-around lg:px-40 lg:border-l-2 lg:border-themed-orange">
        <div className="flex flex-col w-[250px] h-[250px] border-2 border-themed-orange rounded-lg text-center z-20 md:h-[300px]">
          <h3 className="text-default-white font-bold text-2xl p-2 border-b-2 border-themed-orange">
            Discover new places!
          </h3>
          <p className="text-default-white text-l m-auto w-11/12">
            ...or return to your favorite spots!
            <br />
            We partnered up with
            <br />
            <AnimatedCounter from={0} to={52} />
            <br />
            locations across Romania!
          </p>
        </div>
        <div className="flex flex-col w-[250px] h-[250px] border-2 border-themed-orange rounded-lg text-center z-20 md:h-[300px]">
          <h3 className="text-default-white font-bold text-2xl p-2 border-b-2 border-themed-orange">
            Earn back after each visit!
          </h3>
          <p className="text-default-white text-l w-11/12 m-auto">
            For each 100 RON spent at a location, get back an average of
            <br />
            <AnimatedCounter from={0} to={15} />
            <span className="text-themed-orange text-xl font-extrabold md:text-5xl">
              {" "}
              RON
            </span>
            <br />
            by using our rewards system.
          </p>
        </div>
        <div className="flex flex-col w-[250px] h-[250px] border-2 border-themed-orange rounded-lg text-center z-20 md:h-[300px]">
          <h3 className="text-default-white font-bold text-2xl p-2 border-b-2 border-themed-orange">
            Finally, get rewarded!
          </h3>
          <p className="text-default-white text-l w-11/12 m-auto">
            Your loyalty rewards you back! Spend your points on
            <br />
            <AnimatedCounter from={0} to={156} />
            <span className="text-themed-orange text-3xl font-extrabold md:text-5xl">
              +
            </span>
            <br />
            rewards such as discounts, items, products, across your favorite
            places!
          </p>
        </div>
      </div>
    </section>
  );
}
