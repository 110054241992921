import { useInView } from "framer-motion";
import { useRef } from "react";
import { FaAngleUp } from "react-icons/fa6";
import { HashLink } from "react-router-hash-link";
import { AboutUs, Footer, Hero, Navbar, SEO, Showcase } from "../components";
import { SEOdata } from "../data";

export default function Home() {
  const headerRef = useRef<HTMLElement>(null);
  const isInView = useInView(headerRef);

  const { title, description, keywords, faviconUrl, imageUrl, url, jsonLd } =
    SEOdata;

  return (
    <>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        faviconUrl={faviconUrl}
        imageUrl={imageUrl}
        url={url}
        jsonLd={jsonLd}
      />

      <Navbar headerRef={headerRef} />
      <Hero />
      <AboutUs />
      <Showcase />
      <Footer />

      <HashLink
        className="z-40 fixed bottom-0 right-0 w-11 h-11 bg-themed-orange mb-8 mr-10 rounded-full items-center flex justify-center md:mr-20 md:w-14 md:h-14"
        smooth
        to="#header"
        style={{
          opacity: isInView ? "0" : "1",
          transition: "0.2s ease-out",
        }}
      >
        <FaAngleUp className="h-6 w-6 text-dark md:h-7 md:w-7" />
      </HashLink>
    </>
  );
}
