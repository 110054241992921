import { animate, useInView } from "framer-motion";
import { useLayoutEffect, useRef } from "react";
import { AnimatedCounterProps } from "../../types";

export default function AnimatedCounter({
  from,
  to,
  animationOptions,
}: AnimatedCounterProps) {
  const ref = useRef<HTMLSpanElement>(null);
  const inView = useInView(ref, { once: true });

  useLayoutEffect(() => {
    const element = ref.current;

    if (!element) return;
    if (!inView) return;

    element.textContent = String(from);

    const controls = animate(from, to, {
      duration: 1.5,
      ease: "easeOut",
      ...animationOptions,
      onUpdate(value) {
        element.textContent = value.toFixed(0);
      },
    });

    return () => {
      controls.stop();
    };
  }, [ref, from, to, inView, animationOptions]);
  return (
    <span
      ref={ref}
      className="text-themed-orange text-3xl font-extrabold md:text-5xl"
    />
  );
}
