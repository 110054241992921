import { motion } from "framer-motion";
import { TextReveal } from "../../types";

export default function HeroContents(props: TextReveal) {
  const wordVariants = {
    invisible: { opacity: 0, y: 130 },
    visible: { opacity: 1, y: 0 },
  };

  const paragraphVariants = {
    invisible: { opacity: 0, y: -100 },
    visible: { opacity: 1, y: 0 },
  };

  const buttonVariants = {
    invisible: { opacity: 0 },
    visible: { opacity: 1 },
  };

  return (
    <div className="absolute z-10 w-auto h-auto">
      <div className="flex flex-col w-full px-8 justify-between md:px-0">
        <div className="mb-2 overflow-hidden text-center">
          <motion.h1
            className="text-3xl font-bold text-default-white cursor-default h-laptop-sm:text-5xl h-laptop-lg:md:text-7xl h-laptop-lg:lg:text-8xl"
            initial="invisible"
            animate="visible"
            transition={{
              delay: 0.2,
              staggerChildren: 0.4,
            }}
          >
            {props.header
              .split(" ")
              .map((word: string, index: number, array: string[]) => {
                return (
                  <motion.span
                    className="header-span"
                    key={index}
                    variants={wordVariants}
                    transition={{
                      type: "tween",
                      ease: "easeOut",
                      duration: 0.8,
                    }}
                  >
                    {word}
                    {index < array.length - 1 && "\u00A0"}
                  </motion.span>
                );
              })}
          </motion.h1>
        </div>
        <div className="overflow-hidden">
          <motion.p
            className="text-md text-default-white text-center cursor-default md:text-xl"
            initial="invisible"
            animate="visible"
            variants={paragraphVariants}
            transition={{
              type: "tween",
              ease: "easeOut",
              duration: 0.8,
              delay: 0.8,
            }}
          >
            {props.paragraph.split("\n").map((line, index) => (
              <span key={index}>
                {line}
                <br />
              </span>
            ))}
          </motion.p>
          <motion.div
            className="flexCol justify-center w-4/5 mx-auto mt-5 md:flexRow md:w-[450px] lg:w-[500px]"
            initial="invisible"
            animate="visible"
            variants={buttonVariants}
            transition={{
              delay: 1.8,
              duration: 1.2,
            }}
          >
            <button className="flex items-center justify-center mb-3 text-md w-40 text-dark bg-default-white text-center  p-2 rounded-xl md:text-lg md:mb-0">
              <p>App Store </p>
              <img
                className="inline-block h-[20px] w-auto -translate-y-[1px] translate-x-1 md:h-[30px] md:-translate-y-[2px]"
                src="/assets/apple2.webp"
                alt="apple logo"
              />
            </button>
            <button className="flex items-center justify-center text-md w-40 text-dark bg-default-white text-center  p-2 rounded-xl md:text-lg">
              Google Play{" "}
              <img
                className="inline-block h-[20px] w-auto translate-y-[1px] translate-x-1 md:h-[30px]"
                src="/assets/playstore2.webp"
                alt="playstore logo"
              />
            </button>
          </motion.div>
        </div>
      </div>
    </div>
  );
}
