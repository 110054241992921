import { isMobileOnly } from "react-device-detect";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { responsive, showcaseData } from "../../data";
import "../../styles/showcase.css";

export default function Showcase() {
  return (
    <section className="bg-transparent w-screen h-screen flexCol" id="showcase">
      <div className="fixed-overlay z-[3] w-screen h-screen absolute"></div>
      <Carousel
        responsive={responsive}
        arrows
        minimumTouchDrag={isMobileOnly ? 80 : 160}
        containerClass="w-full h-[470px] md:h-auto z-20"
      >
        {showcaseData.map((data) => {
          return (
            <div
              key={data.id}
              className="flexRow w-[65%] h-auto mx-auto top-1/2 -translate-y-1/2 relative md:top-0 md:translate-y-0 md:w-2/3 lg:w-1/2"
            >
              {!isMobileOnly && (
                <img
                  className="h-[30vh] w-auto pr-20 pointer-events-none md:h-[40vh] md:pr-10 lg:h-[70vh]"
                  src={data.imgSrc}
                  alt={data.imgAlt}
                />
              )}

              <div className="flex flex-col text-left text-default-white">
                <h2 className="font-bold border-b border-default-white pb-4 text-4xl md:text-6xl">
                  <span className="text-themed-orange">{data.span} </span>
                  {data.heading}
                </h2>
                <p className="pt-3 md:pt-5">{data.paragraph}</p>
              </div>
            </div>
          );
        })}
      </Carousel>
    </section>
  );
}
