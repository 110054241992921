import { useMotionValue } from "framer-motion";
import { useEffect } from "react";

export default function useMousePosition() {
  const size = 200;
  const x = useMotionValue(0);
  const y = useMotionValue(0);

  useEffect(() => {
    const updateMousePosition = (e: MouseEvent) => {
      x.set(e.clientX - size / 2);
      y.set(e.pageY - size / 2);
    };

    window.addEventListener("mousemove", updateMousePosition);
    return () => {
      window.removeEventListener("mousemove", updateMousePosition);
    };
  }, [x, y]);

  return { x, y };
}
