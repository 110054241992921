import { NavbarProps } from "../../types";
import Logo from "./Logo";
import NavLinks from "./NavLinks";

export default function Navbar(props: NavbarProps) {
  return (
    <header
      ref={props.headerRef}
      className="absolute top-0 z-20 px-8 flex w-full items-center justify-between mt-8 ipad:mt-14 ipad:px-16 md:px-28"
      id="header"
    >
      <Logo />
      <NavLinks />
    </header>
  );
}
