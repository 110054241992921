import { motion, useMotionTemplate } from "framer-motion";
import { BrowserView } from "react-device-detect";
import { heroContents } from "../../data/index";
import "../../styles/hero.css";
import useMousePosition from "../../utils/useMousePosition";
import HeroContents from "./HeroContents";

export default function Hero() {
  const { x, y } = useMousePosition();
  const maskPos = useMotionTemplate`${x}px ${y}px`;

  const imgVariants = {
    invisible: { opacity: 0, bottom: -300 },
    visible: { opacity: 1, bottom: -130 },
  };

  return (
    <section className="w-screen min-h-screen flex items-center justify-center">
      <HeroContents {...heroContents} />
      <div className="overlay absolute z-[4] top-0 left-0 w-screen h-screen"></div>
      <BrowserView>
        <motion.div
          className="mask absolute z-[3] w-screen h-full top-0 left-0"
          style={{
            WebkitMaskPosition: maskPos,
          }}
          transition={{ type: "tween", ease: "easeOut" }}
        ></motion.div>
        <motion.div
          className="absolute -bottom-[130px] left-0 z-[3] h-[45vh] w-screen overflow-y-hidden flex justify-center"
          initial="invisible"
          animate="visible"
          variants={imgVariants}
          transition={{
            type: "tween",
            ease: "easeOut",
            duration: 1,
            delay: 2,
          }}
        >
          <img
            className="w-auto h-full top-0"
            src="/assets/screen_shots/explore.webp"
            alt="Explore"
          />
        </motion.div>
      </BrowserView>
    </section>
  );
}
