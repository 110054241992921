export const showcaseData = [
  {
    id: 1,
    heading: "places.",
    span: "Explore",
    paragraph:
      "Every outing is an adventure, blending familiar spots with hidden gems. Rediscover favorites and find new experiences, guided by technology. Turn each trip into an exciting journey.",
    imgSrc: "/assets/screen_shots/explore.webp",
    imgAlt: "Explore",
  },
  {
    id: 2,
    heading: "points.",
    span: "Earn",
    paragraph:
      "Earn rewards effortlessly by scanning a QR code after purchases. Earn points equal to your spending, and redeem them for discounts, offers, or free items. Turn every outing into a chance to save and enjoy more.",
    imgSrc: "/assets/screen_shots/qr.webp",
    imgAlt: "QRCode",
  },
  {
    id: 3,
    heading: "up.",
    span: "Level",
    paragraph:
      "Earn experience points with every purchase at your favorite spots. Each point levels up your rewards, unlocking exclusive offers and personalized perks. Enjoy more savings and benefits with every outing, one point at a time.",
    imgSrc: "/assets/screen_shots/points.webp",
    imgAlt: "Points",
  },
  {
    id: 4,
    heading: "show off to your friends!",
    span: "And",
    paragraph:
      "Visit your profile page to track your progress and achievements. Monitor levels, view accumulated points, and see upcoming rewards. Your profile showcases every milestone and perk, helping you maximize the benefits of each outing.",
    imgSrc: "/assets/screen_shots/profile.webp",
    imgAlt: "Profile",
  },
];

export const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 2,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export const navLinksData = [
  {
    id: 1,
    className: "nav",
    to: "#about-us",
    text: "About us",
  },
  {
    id: 2,
    className: "nav",
    to: "#showcase",
    text: "Showcase",
  },
];

export const heroContents = {
  header: "Welcome to Resturo",
  paragraph:
    "Unlock exclusive rewards at your favorite locations.\nDownload now to enhance your dining experience!",
};

export const SEOdata = {
  title: "Resturo Loyality App for Restaurants, Coffee Shops and Bars",
  description:
    "Resturo is a loyalty rewards app that enhances customer experiences at restaurants, cafes, and bars with personalized rewards through virtual wallets.",
  keywords: [
    "resturo",
    "exclusive rewards",
    "loyalty rewards app",
    "earn and redeem points for special benefits",
    "explore places",
    "unlock exclusive offers",
    "exclusive rewards at restaurants",
  ],
  faviconUrl: "https://resturo.app/assets/favicon.ico",
  imageUrl: "https://resturo.app/assets/logo.webp",
  url: "https://resturo.app/",
  jsonLd: {
    "@context": "https://schema.org",
    "@type": "SoftwareApplication",
    additionalType: "https://schema.org/MobileApplication",
    name: "Resturo",
    url: "https://resturo.app/",
    image: "https://resturo.app/assets/logo.webp",
    description:
      "Resturo is a new loyalty rewards app that improves customer experiences at restaurants, coffee shops, and bars. It offers a personalized rewards system through virtual wallets, where customers can earn and redeem points for special benefits.",
    applicationCategory: "BusinessApplication",
    operatingSystem: "Web",
    softwareVersion: "1.0",
    // downloadUrl: [
    //   "https://apps.apple.com/app/idAPP_ID",
    //   "https://play.google.com/store/apps/details?id=APP_ID",
    // ], when the time is right
    // sameAs: [
    //   "https://www.facebook.com/Resturo",
    //   "https://twitter.com/Resturo",
    //   "https://www.instagram.com/Resturo",
    // ], if necessary
  },
};
