import { HashLink } from "react-router-hash-link";
import { navLinksData } from "../../data";

export default function NavLinks() {
  return (
    <nav className="w-[180px] md:w-[220px] lg:w-[270px]">
      <div className="flex justify-between text-default-white text-md">
        {navLinksData.map((data) => {
          return (
            <HashLink
              key={data.id}
              smooth
              to={data.to}
              className={data.className}
            >
              {data.text}
            </HashLink>
          );
        })}
      </div>
    </nav>
  );
}
